div.checkout-page {
    font-family: Avenir-Roman;
    padding: 100px 5px;
    background-color: var(--black-color);
    min-height: 90vh;

    span.heading {
        text-transform: none;
        color: #fff;
        letter-spacing: 0.5px;
        font-size: 40px;

        @media only screen and (max-width: 768px) {
            font-size: 30px;
        }
    }



    div.sign-in-link-section {
        margin-bottom: 30px;
        text-align: right;
        font-size: medium;
        font-weight: lighter;

        @media only screen and (max-width: 768px) {
            text-align: left;
        }

        .sub-heading {
            margin-right: 10px;
            font-size: 15px;
        }

        .signin-link {
            font-size: medium;
            cursor: pointer;
            color: #2dbebd;

            &:hover {
                color: #fff;
            }
        }
    }

    div.wrapper {
        display: flex;
        justify-content: space-between;

        @media only screen and (max-width: 768px) {
            flex-direction: column;
        }

        .left {
            .user-section {

                @media only screen and (min-width: 768px) {
                    width: 485px;
                }

                @media only screen and (min-width: 1024px) {
                    width: 420px;
                    margin-right: 40px;
                }


                .forgot-link {
                    margin-top: 20px;
                    display: flex;
                    justify-content: space-between;

                    .forgot-text {
                        margin-top: -5px;
                    }
                }



                .login-with-email-password {
                    text-align: center;
                    margin: 20px 0px;
                    background: #12121266;
                    padding: 10px;
                    border-radius: 2px;
                }

                .login-with-email-wrapper {
                    margin: 20px 0px;

                    button {
                        padding: 10px;
                        width: 100%;
                        // background: #2dbebd;
                        background: var(--button-color);
                        // color: #121212;
                        color: var(--text-color);
                        border-radius: 2px;
                        font-size: 17px;

                        &:hover {
                            font-weight: 600;
                            background: #cccccc17;
                            color: #2dbebd;
                        }
                    }
                }

                .seperate {
                    display: flex;
                    margin: 20px 0px;

                    .line {
                        width: 50%;
                        background: #cccccc57;
                        height: 1px;
                        margin-top: 10px;
                    }

                    .devider {
                        margin: 0px 5px;
                        color: rgba(204, 204, 204, 0.3411764706);
                        font-size: medium;
                    }
                }

                .existing-user-message {
                    width: 485px;
                    padding: 15px;
                    background: #121212;
                    margin: 20px 0px 0px 0px;
                    border-radius: 2px;

                    @media only screen and (max-width: 768px) {
                        width: 100%;
                    }

                    @media only screen and (min-width: 1024px) {
                        width: 420px;
                    }

                    .close-message {
                        font-size: x-small;
                        border: 1px solid #ffffff82;
                        padding: 0px 5px;
                        border-radius: 50%;
                        cursor: pointer;
                        float: right;
                        margin-top: -10px;
                        margin-right: -10px;
                        color: #ffffff82;
                    }
                }

                .login-success-message {
                    max-width: 485px;
                    background: #121212;
                    padding: 25px;
                    border-radius: 2px;
                    font-size: large;
                    margin-top: 20px;

                    @media only screen and (max-width: 768px) {
                        width: 100%;
                        padding: 20px;
                        font-size: medium;
                    }
                }

                // .login-error {
                //     color: red;
                //     max-width: 377px;
                // }

                .login-button {
                    width: 100px;
                    height: 35px;
                    border-radius: 2px;
                    font-size: 17px;
                    // background: #2dbebd;
                    background: var(--button-color);
                    // color: #121212;
                    color: var(--text-color);
                    float: right;
                    // margin-top: 20px;

                    &:hover {
                        background: #cccccc17;
                        color: #2dbebd;
                    }
                }

                .password-section {

                    margin-top: 25px;

                    .password-show {
                        float: right;
                        font-weight: 600;
                        cursor: pointer;
                        color: #2dbebd;
                        position: relative;
                        top: 35px;
                        right: 10px;

                        &:hover {
                            color: #fff;
                        }
                    }
                }

                input {
                    width: 100%;
                    height: 50px;
                    border: 1px solid #ddd;
                    border-radius: 2px;
                    padding: 10px;
                }
            }

            .coupon-wrapper {
                .coupon-link {

                    display: flex;
                    margin-bottom: 10px;

                    .coupon-text {
                        margin-top: 12px;
                        margin-right: 10px;
                    }

                    .coupon-check {
                        width: 15px;
                        cursor: pointer;
                        margin-top: 12px;
                        margin-right: 5px;
                    }



                }

                .coupon-section {
                    input {
                        width: 65%;
                        margin-right: 5%;
                        height: 35px;
                        padding: 10px;
                    }

                    button {
                        width: 30%;
                        height: 34px;
                        border-radius: 2px;
                         // background: #2dbebd;
                         background: var(--button-color);
                         // color: #121212;
                         color: var(--text-color);

                        &:hover {
                            background: #cccccc17;
                            color: #2dbebd;
                        }
                    }
                }
            }

            @media only screen and (min-width: 1024px) {
                width: 420px;
                margin-right: 40px;
            }


        }

        .right {
            width: 60%;
            border-left: 2px solid #dddddd3d;
            padding-left: 70px;

            @media only screen and (max-width: 768px) {
                border-left: transparent;
                border-top: 2px solid;
                width: 100%;
                margin-top: 20px;
                padding: 10px 0px;
            }

            span.heading {
                text-transform: none;
                color: #fff;
                letter-spacing: 0.5px;
                font-size: 30px;

                @media only screen and (max-width: 768px) {
                    font-size: 20px;
                }
            }

            .package-section {
                p.disclaimer-text {
                    font-size: smaller;

                    span {
                        font-size: small !important;
                    }
                }

                .package-item {
                    padding: 20px;
                    background: #cccccc17;
                    border-radius: 3px;
                    margin: 10px 0px;

                    .plan-description {
                        color: #fff;
                        max-height: 150px;
                        overflow-y: scroll;
                    }
                }

                .heading-section {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;

                    .plan-options-wrapper {
                        align-content: center;
                        margin-top: 20px;
                        cursor: pointer;
                    }

                    .plan-options {
                        width: 20px;
                        height: 20px;
                        cursor: pointer !important;
                    }

                    .heading-wrapper {
                        display: flex;
                        flex-direction: column;
                    }

                    .heading {
                        font-size: 25px;

                        @media only screen and (max-width: 768px) {
                            font-size: 15px;
                        }
                    }
                }

                .details {
                    color: #2dbebd;
                    cursor: pointer;
                }

                .continue-button {
                    width: 200px;
                    height: 50px;
                    border-radius: 2px;
                    font-size: 20px;
                     // background: #2dbebd;
                     background: var(--button-color);
                     // color: #121212;
                     color: var(--text-color);
                    float: right;
                    @media only screen and (max-width: 768px) {
                        width: 100%;
                        height: 40px;
                        font-size: 18px;
                    }
    
                    &:hover {
                        background: #cccccc17;
                        color: #2dbebd;
                    }
                }
            }
             
            .paypal-container{
                margin-left: 30%;
                width: 40%;
                @media all and (max-width: 980px) {
                    margin-left: 22%;
                  }
                @media all and (max-width: 980px) {
                    margin-left: 30%;
                  }
                @media all and (max-width: 480px) {
                    margin-left: 27%;
                  }
                @media all and (max-width: 360px) {
                    margin-left: 19%;
                  }
                @media all and (max-width: 320px) {
                    margin-left: 15%;
                  }
                  
            }
            
        
            div.subscription-listing {
                padding: 20px;
                background: rgba(204, 204, 204, 0.09);
                border-radius: 3px;
                margin: 10px 0;
                @media all and (max-width: 980px) {
                    padding: 20px 40px;
                  }
                div.subscription-heading {
                    display: flex;
                    justify-content: space-between;
            
                    h2 {
                        font-size: 18px;
                    }
                }
        
                div.payment-gateways {
                    display: flex;
                    flex-wrap: wrap;
                    padding: 1% 7.6%;
                    @media all and (max-width: 1200px) {
                        padding: 1%;
                      }
                    @media all and (max-width: 980px) {
                        padding: 1% 3%;
                      }
                    .gateway-item {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 5px;
                            cursor: pointer;
                            transition: transform 0.2s ease-in-out;
                            margin-right: 5%;
                            &:nth-child(4n) {
                                margin-right:0;
                              }
                        &:hover {
                            transform: scale(1.05);
                        }
                        @media all and (max-width: 2600px) {
                            margin-right: 4%;
                            &:nth-child(4n) {
                                margin-right:0;
                              }
                          }
                        @media all and (max-width: 1400px) {
                            margin-right: 3%;
                            &:nth-child(4n) {
                                margin-right:0;
                              }
                          }
                         @media all and (max-width: 1200px) {
                            margin-right: 2%;
                            &:nth-child(4n) {
                                margin-right:0%;
                              }
                          }
                          @media all and (max-width: 980px) {
                            margin-right: 8%;
                            &:nth-child(2n) {
                                margin-right:0;
                              }
                          }
                          @media all and (max-width: 768px) {
                            &:nth-child(2n) {
                                margin-right:8%;
                              }
                            &:nth-child(4n) {
                                margin-right: 0;
                              }
                          }
                          @media all and (max-width: 640px) {
                            margin-right: 3%;
                            &:nth-child(2n) {
                                margin-right: 3%;
                              }
                            &:nth-child(4n) {
                                margin-right: 0;
                              }
                          }
                          @media all and (max-width: 480px) {
                            margin-right: 3%;
                            &:nth-child(4n) {
                                margin-right: 3%;
                              }
                            &:nth-child(3n) {
                                margin-right: 0;
                              }
                          }
                          @media all and (max-width: 360px) {
                            margin-right: 6%;
                            &:nth-child(3n) {
                                margin-right: 6%;
                              }
                            &:nth-child(2n) {
                                margin-right: 0;
                              }
                          }
                        .payment-method {
                            width: 100%;
                            height: 100%;
                
                            ul {
                                display: flex;
                                justify-content: center;
                
                                li {
                                    .imageContainer,
                                    .bogimageContainer,
                                    .cinetImageContainer,
                                    .squareImageContainer {
                                        width: 130px;
                                        height: 80px;
                                        border-radius: 7px;

                                       
                                        @media all and (max-width: 2600px) {
                                            width: 125px !important;
                                            height: 75px !important;
                                          }
                                        @media all and (max-width: 2500px) {
                                            width: 107px ;
                                            height: 64px ;
                                          }
                                        @media all and (max-width: 1200px) {
                                            width: 115px !important;
                                            height: 70px !important;
                                          }
                                        @media all and (max-width: 980px) {
                                           width: 100px !important;
                                           height: 60px !important;
                                          } 
                                        @media all and (max-width: 320px) {
                                            width: 90px !important;
                                            height: 55px !important;
                                           }  
                                          

                                        img {
                                            width: 100%;
                                            height: 100%;
                                            border-radius: 9px;
                                        }
                                    }
                
                                    .paypalImageContainer {
                                        width: 130px !important;
                                        height: 130px ;
                                        border-radius: 7px;
                                        margin-top: -25%;
                                        @media all and (max-width: 2600px) {
                                            width: 127px !important;
                                            height: 75px !important;
                                          }
                                        @media all and (max-width: 2500px) {
                                            width: 125px !important;
                                          }
                                        @media all and (max-width: 1200px) {
                                            width: 120px !important;
                                            height: 75px !important;
                                          }
                                        @media all and (max-width: 980px) {
                                            width: 100px !important;
                                          }
                                        @media all and (max-width: 320px) {
                                            width: 90px !important;
                                          }

                                     
                                        img {
                                            width: 100%;
                                            height: 100%;
                                        }
                                    }
                
                                    .bogimageContainer img,
                                    .cinetImageContainer img {
                                        margin-top: 20%;
                                    }
                
                                    .paypalImageContainer img {
                                        margin-top: 6% !important;
                                        @media all and (max-width: 2600px) {
                                            margin-top: 7% !important;
                                            height: 119px !important; 
                                          }
                                        @media all and (max-width: 1200px) {
                                           margin-top: 5% !important;
                                           height: 117px !important;
                                          }
                                        @media all and (max-width: 980px) {
                                            margin-top: 5% !important;
                                            height: 102px !important;
                                            width: 104px !important;
                                           }
                                        @media all and (max-width: 320px) {
                                            height: 91px !important;
                                           }
                                    }
                                }
                            }
                        }
                    }
                }
                
                
            
                div.paypal-container {
                    width: 70%;
                    align-self: center;
                }
            }
            
        }
    }

    .link {
        font-size: medium;
        cursor: pointer;
        color: #2dbebd;

        &:hover {
            color: #fff;
        }
    }

    .white-link {
        cursor: pointer;
        color: #fff;

        &:hover {
            color: #774ed6;
        }
    }

    .error {
        color: red;
    }

    .success {
        color: #1bbf1b;
        margin-top: 5px;
    }

}